import { Route, Switch, useLocation } from 'react-router-dom';

import { LocationState } from 'src/Services/Routing';

import CompanyStudies from '../CompanyStudies';
import Home from '../Home';
import PositionStudyModification from '../PositionStudyModification';

export const Studies = () => {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;

  return (
    <Switch location={background || location}>
      <Route exact path={'/studies'} component={Home} />
      <Route exact path="/studies/company/:companyId?" component={CompanyStudies} />
      <Route
        path="/studies/study/:positionStudyId/:firstSection?/:secondSection?"
        component={PositionStudyModification}
      />
    </Switch>
  );
};

import { NavigateDown, NavigateUp } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Animation, Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import { Fragment, useCallback, useMemo, useState } from 'react';

import styles from './RegionSelector.module.scss';
import { RegionSelectorProps } from './RegionSelector.types';

export const RegionSelector = ({
  region,
  selectedRegions,
  setSelectedRegions,
}: RegionSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const regionAgencyIds = useMemo(
    () => region.zones.flatMap(zone => zone.agencies.map(agency => agency.agencyId)),
    [region.zones]
  );

  const selectedAgencies = useMemo(
    () => selectedRegions.find(({ regionId }) => region.regionId === regionId)?.agencyIds ?? [],
    [region.regionId, selectedRegions]
  );

  const toggleAgencies = useCallback(
    (agencyIds: string[]) =>
      setSelectedRegions(selectedRegions => {
        if (!selectedRegions.some(({ regionId }) => region.regionId === regionId)) {
          return selectedRegions.concat([
            { regionId: region.regionId, regionTitle: region.regionTitle, agencyIds },
          ]);
        }
        return selectedRegions
          .map(selectedRegion =>
            selectedRegion.regionId !== region.regionId
              ? selectedRegion
              : {
                  ...selectedRegion,
                  agencyIds: selectedRegion.agencyIds.includes(agencyIds[0])
                    ? selectedRegion.agencyIds.filter(agencyId => !agencyIds.includes(agencyId))
                    : [...selectedRegion.agencyIds, ...agencyIds],
                }
          )
          .filter(region => region.agencyIds.length > 0);
      }),
    [region.regionId, region.regionTitle, setSelectedRegions]
  );

  return (
    <div>
      <div className={styles.regionHeader} onClick={() => setIsOpen(!isOpen)}>
        <div onClick={event => event.stopPropagation()}>
          <Checkbox
            checked={regionAgencyIds.every(agencyId => selectedAgencies.includes(agencyId))}
            halfChecked={regionAgencyIds.some(agencyId => selectedAgencies.includes(agencyId))}
            onChange={() => toggleAgencies(regionAgencyIds)}
          />
        </div>
        <div className={styles.regionTitle}>
          {region.regionId} - {region.regionTitle}
        </div>
        {isOpen ? <NavigateUp color="blue" /> : <NavigateDown color="blue" />}
      </div>
      <Animation.Unroll visible={isOpen}>
        {region.zones.map(zone => {
          const zoneAgencyIds = zone.agencies.map(agency => agency.agencyId);
          return (
            <Fragment key={zone.zoneId}>
              <div className={styles.zoneTitle}>
                <Checkbox
                  checked={zoneAgencyIds.every(agencyId => selectedAgencies.includes(agencyId))}
                  halfChecked={zoneAgencyIds.some(agencyId => selectedAgencies.includes(agencyId))}
                  onChange={() => toggleAgencies(zoneAgencyIds)}
                >
                  {zone.zoneId} - {zone.zoneTitle}
                </Checkbox>
              </div>
              <div className={styles.zone}>
                {zone.agencies.map(agency => (
                  <Checkbox
                    key={agency.agencyId}
                    checked={selectedAgencies.includes(agency.agencyId)}
                    onChange={() => toggleAgencies([agency.agencyId])}
                  >
                    <div className={styles.agencyTitle}>{agency.agencyId}</div>
                  </Checkbox>
                ))}
              </div>
            </Fragment>
          );
        })}
      </Animation.Unroll>
    </div>
  );
};

import invariant from 'invariant';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface DashboardStateContext {
  selectedRegions: { regionId: string; regionTitle: string; agencyIds: string[] }[];
  setSelectedRegions: Dispatch<
    SetStateAction<{ regionId: string; regionTitle: string; agencyIds: string[] }[]>
  >;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const dashboardStateContext = createContext<DashboardStateContext>(undefined!);

export const DashboardStateProvider = ({ children }: PropsWithChildren) => {
  const [selectedRegions, setSelectedRegions] = useState<
    { regionId: string; regionTitle: string; agencyIds: string[] }[]
  >([]);

  const value = useMemo(
    (): DashboardStateContext => ({
      selectedRegions,
      setSelectedRegions,
    }),
    [selectedRegions]
  );

  return <dashboardStateContext.Provider value={value}>{children}</dashboardStateContext.Provider>;
};

export const useDashboardState = () => {
  const context = useContext(dashboardStateContext);
  invariant(context, 'useDashboardState must be used within DashboardStateProvider');

  return [context.selectedRegions, context.setSelectedRegions] as const;
};

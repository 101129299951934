import moment from 'moment';
import React from 'react';

import { getCompletionStatusData } from 'src/Components/PositionStudyCard/PositionStudyCard.types';

import styles from './ListCard.module.scss';
import { Props } from './ListCard.types';

export const ListCard = ({
  name,
  completionStatus,
  qualification,
  updatedAt,
  onClick,
  rightActions,
  service,
  siret,
  serviceAddress,
}: Props) => (
  <div className={styles.container} onClick={onClick}>
    <div className={styles.firstPart}>
      <p className={styles.date}>{moment(updatedAt).locale('fr').format('LLL')}</p>
      <p className={styles.service}>{service}</p>
      <p className={styles.siret}>{siret}</p>
      <p className={styles.serviceAddress}>{serviceAddress}</p>
      {completionStatus && (
        <div className={styles.completionStatusContainer}>
          {getCompletionStatusData(completionStatus).icon}
          <p className={styles.completionStatus}>
            {getCompletionStatusData(completionStatus).label}
          </p>
        </div>
      )}
    </div>

    <div className={styles.secondPart}>
      <p className={styles.qualification}>qualification : {qualification}</p>
      <p className={styles.name}>nom de l'EDP : {name}</p>
    </div>
    <div className={styles.thirdPart}>{rightActions}</div>
  </div>
);

export default ListCard;

import { FileDropzone, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage,
  TextArea,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CompletionStatusMessagesComponent from 'src/Components/CompletionStatusMessages';
import ContactPicker from 'src/Components/ContactPicker';
import RequiredFieldHeader from 'src/Components/RequiredFieldHeader/RequiredFieldHeader.component';
import CompanyRightsModal from 'src/Containers/Modals/CompanyRightsModal/CompanyRightsModal.component';
import ConsultantRightsModal from 'src/Containers/Modals/ConsultantRightsModal/ConsultantRightsModal.component';
import { SectorSelection } from 'src/Containers/Modals/EDPCreationModal/SectorSelectionModalContent/SectorSelection';
import { PositionStudyModificationContext } from 'src/Containers/PositionStudyModification/PositionStudyModificationContext';
import { useHasCompanyGivenPhotoRights } from 'src/Hooks/Companies/useHasCompanyGivenPhotoRights';
import { useHasConsultantGivenPhotoRights } from 'src/Hooks/Consultants/useHasConsultantGivenPhotoRights';
import { useDeletePositionStudyFile } from 'src/Hooks/PositionStudies/useDeletePositionStudyFile';
import { useFetchPositionStudyFiles } from 'src/Hooks/PositionStudies/useFetchPositionStudyFiles';
import { useFetchPositionStudyFileData } from 'src/Hooks/PositionStudies/useFetchPositionStudyFilesData';
import { useUploadPositionStudyFile } from 'src/Hooks/PositionStudies/useUploadPositionStudyFiles';
import {
  getCurrentHasEpcs,
  getCurrentHasEpis,
  getCurrentHasRisks,
  getRequiredFields,
} from 'src/Redux/CompletionStatus/Selectors';
import { completionStatusActions } from 'src/Redux/CompletionStatus/Slice';
import { FETCH_STATUS } from 'src/Redux/Types';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import {
  EnumPositionStudyProtectionEquipmentsHasEpcs,
  EnumPositionStudyProtectionEquipmentsHasEpis,
  EnumPositionStudyRisksHasRisks,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { bytesToSize } from 'src/Utils/bytesToSize';
import { ACTIVITY_SECTORS } from 'src/Utils/constants';
import { onFileOpen } from 'src/Utils/onFileOpen';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './Practical.module.scss';
import { practicalInfoSchema } from './Practical.schema';
import { DropzoneItem, UploadStatus } from './Practical.types';
import { PracticalContext } from './PracticalContext';
import ServiceSelection from './ServiceSelection';
import { WorkingLocationSelection } from './WorkingLocationSelection';

const emptyLocation = {
  inseeCode: undefined,
  cityName: undefined,
  zipCode: undefined,
  address: undefined,
};

const dropzoneData = {
  maxFiles: 20,
  maxSize: 5000000,
  accept: {
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'image/*': ['.jpg', '.png', '.gif'],
  },
  advice: 'ajouter tous document utiles',
};

const Practical = () => {
  const isMobile = useSelector(getDevice);
  const { positionStudyId } = useParams<{
    positionStudyId: string;
  }>();
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const updateMutation = positionStudyModificationContext.updateMutation;
  const fetchPositionStudy = positionStudyModificationContext.fetchPositionStudy;
  const companyId = fetchPositionStudy.data?.generalInformation.company?.companyId;
  const positionBriefFetchStatus = toFetchStatus(fetchPositionStudy);
  const practicalSection = fetchPositionStudy.data?.practicalInformation;
  const uploadMutation = useUploadPositionStudyFile();
  const deletePositionStudyFile = useDeletePositionStudyFile();
  const fetchPositionStudyFileData = useFetchPositionStudyFileData();
  const { data, isLoading, isError, isSuccess } = useFetchPositionStudyFiles(
    fetchPositionStudy.data?.generalInformation.positionStudyId ?? ''
  );
  const consultantRightQuery = useHasConsultantGivenPhotoRights();
  const companyRightQuery = useHasCompanyGivenPhotoRights(companyId);
  const companyRightQueryFetchStatus = toFetchStatus(companyRightQuery);
  const [openConsultantRightsModal, setOpenConsultantRightsModal] = useState(false);

  const dispatch = useDispatch();
  const requiredFields = useSelector(getRequiredFields);
  const hasRisks = useSelector(getCurrentHasRisks);
  const hasEpis = useSelector(getCurrentHasEpis);
  const hasEpcs = useSelector(getCurrentHasEpcs);

  const [files, setFiles] = useState(
    data?.map(
      file =>
        ({
          id: file.id,
          subtitle: bytesToSize(file.size),
          file: {
            name: file.name,
            size: file.size,
            type: file.mimetype,
          } as File,
          status: UploadStatus.None,
        } as DropzoneItem)
    ) ?? []
  );
  useEffect(() => {
    setFiles(
      data?.map(file => {
        return {
          id: file.id,
          subtitle: bytesToSize(file.size),
          file: {
            name: file.name,
            size: file.size,
            type: file.mimetype,
          } as File,
          status: UploadStatus.None,
        } as DropzoneItem;
      }) ?? []
    );
  }, [data]);

  const onFileDropAction = (items: DropzoneItem[]) => {
    const promises = items
      .filter(item => item.status === UploadStatus.InProgress)
      .map(item => {
        return uploadMutation.mutateAsync({
          id: fetchPositionStudy.data?.generalInformation.positionStudyId ?? '',
          file: item.file,
        });
      });
    Promise.all(promises).then(dbFiles => {
      setFiles(
        items.map(item => ({
          ...item,
          id: item.id ?? dbFiles.find(dbFile => dbFile.name === item.file.name)?.id,
          status: item.status === UploadStatus.InProgress ? UploadStatus.Done : item.status,
        }))
      );
    });
  };
  const onFileDropped = (items: DropzoneItem[]) => {
    setFiles(items);
    if (items.length > 0) {
      if (
        consultantRightQuery.data === false &&
        items.find(item => item.file.type.includes('image') && item.id === undefined) !== undefined
      ) {
        setOpenConsultantRightsModal(true);
      } else {
        onFileDropAction(items);
      }
    } else {
      setFiles([]);
    }
  };

  const onFileDeleted = async (item: DropzoneItem) => {
    const result = await deletePositionStudyFile.mutateAsync({
      id: item.id ?? '',
    });
    if (result) setFiles(files.filter(deletedItem => item.file.name !== deletedItem.file.name));
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: practicalInfoSchema,
    defaultValues: {
      workLocation: {
        workingLocation: practicalSection?.workLocation,
        isMobileWorkLocation: practicalSection?.isMobileWorkLocation,
        mobileWorkLocationDepartment: practicalSection?.isMobileWorkLocation
          ? practicalSection.workLocation?.zipCode?.substring(0, 2)
          : undefined,
        customWorkingLocation: practicalSection?.isMobileWorkLocation
          ? emptyLocation
          : practicalSection?.workLocation,
      },
      service: practicalSection?.service?.serviceId ? practicalSection?.service : undefined,
      contactId: practicalSection?.contactId,
      jobInfo: practicalSection?.jobInfo,
      sector: practicalSection?.sector ?? undefined,
    },
  });

  const selectedSector = watch('sector');
  const service = watch('service');
  const contactId = watch('contactId');
  const jobInfo = watch('jobInfo');
  const workLocation = watch('workLocation');

  const shouldDisplaySectorWarning = selectedSector?.id === '' || selectedSector?.id === undefined;
  const isNotCommerceSector = selectedSector?.id !== ACTIVITY_SECTORS.TRADE_AND_SERVICES;
  const missingRequiredFields = [
    shouldDisplaySectorWarning && 'selectedSector',
    (contactId === undefined || contactId === null) && 'contactId',
    ((!workLocation.isMobileWorkLocation &&
      workLocation.workingLocation?.zipCode === undefined &&
      (workLocation.customWorkingLocation?.address === undefined ||
        (workLocation.customWorkingLocation?.address !== undefined &&
          workLocation.customWorkingLocation.address.length === 0) ||
        workLocation.customWorkingLocation?.cityName === undefined)) ||
      (workLocation.isMobileWorkLocation &&
        workLocation.mobileWorkLocationDepartment === undefined)) &&
      'zipCode',
  ].filter(Boolean);

  const practicalHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        updateMutation.mutate({
          // we authorize a non-null insertion because to arrive to this step we have a postitionStudy
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialPositionStudy: fetchPositionStudy.data!,
          modification: {
            practicalInformation: {
              isMobileWorkLocation: values.workLocation.isMobileWorkLocation,
              workLocation: values.workLocation.isMobileWorkLocation
                ? {
                    zipCode: [values.workLocation.mobileWorkLocationDepartment, '000'].join(''),
                  }
                : values.workLocation.workingLocation ??
                  (values.workLocation.customWorkingLocation?.zipCode !== undefined
                    ? values.workLocation.customWorkingLocation
                    : emptyLocation),
              service: values.service ?? { serviceId: '', name: '' },
              contactId: values.contactId,
              jobInfo: values.jobInfo,
              sector: values.sector,
            },
          },
        });
        reset(values);
      }),
    [fetchPositionStudy.data, handleSubmit, reset, updateMutation]
  );

  const submit = practicalHandleSubmit();

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    dispatch(completionStatusActions.setSelectedSector(selectedSector ?? { id: '', label: '' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, contactId, selectedSector]);

  useEffect(() => {
    const hasMissingSelectedSector = missingRequiredFields.includes('selectedSector');
    const hasMissingContactId = missingRequiredFields.includes('contactId');
    const hasMissingZipCode = missingRequiredFields.includes('zipCode');

    const newRequiredFields = {
      ...requiredFields,
      hr: {
        ...requiredFields.hr,
        usefulinfo: {
          ...requiredFields.hr.usefulinfo,
          selectedSector: hasMissingSelectedSector,
          contactId: hasMissingContactId,
          zipCode: hasMissingZipCode,
        },
      },
      security: {
        ...requiredFields.security,
        risks: {
          risks: hasRisks === EnumPositionStudyRisksHasRisks.Unknown && isNotCommerceSector,
        },
        protectionEquipment: {
          hasEpis:
            hasEpis === EnumPositionStudyProtectionEquipmentsHasEpis.Unknown && isNotCommerceSector,
          hasEpcs:
            hasEpcs === EnumPositionStudyProtectionEquipmentsHasEpcs.Unknown && isNotCommerceSector,
        },
      },
    };

    dispatch(completionStatusActions.setRequiredFields(newRequiredFields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSector,
    contactId,
    workLocation,
    workLocation.customWorkingLocation,
    workLocation.isMobileWorkLocation,
    workLocation.mobileWorkLocationDepartment,
  ]);

  return (
    <PracticalContext.Provider
      value={{
        control,
        watch,
        setValue,
        submit,
      }}
    >
      {positionBriefFetchStatus === FETCH_STATUS.FULFILLED && (
        <div className={styles.container}>
          <div>
            {missingRequiredFields.length > 0 && (
              <CompletionStatusMessagesComponent missingRequiredFields={missingRequiredFields} />
            )}
          </div>
          <h2>secteur d'activité</h2>
          <div className={classnames([styles.elementContainer, styles.sectorsContainer])}>
            <WithLightTitle
              title="(*info attendue)"
              titleClassName={styles.expectedInformationSector}
            >
              <SectorSelection
                selectedSector={selectedSector}
                onSectorChange={sector => setValue('sector', sector, { shouldDirty: true })}
                isVariableSize
                displayWarning={shouldDisplaySectorWarning}
              />
              {shouldDisplaySectorWarning && (
                <div className={styles.warningMessageSector}>secteur manquant</div>
              )}
            </WithLightTitle>
          </div>
          <hr />
          <WorkingLocationSelection />
          <hr />
          <div className={styles.elementContainer}>
            <h2 className={styles.subtitle}>contact référent</h2>
            <RequiredFieldHeader fieldHeader="liste des référents" isMobile={isMobile} />
            <ContactPicker
              name="contactId"
              companyId={companyId ?? ''}
              control={control}
              shouldNotBeEmpty={!contactId}
            />
            {!contactId && <div className={styles.warningMessage}>contact manquant</div>}
          </div>
          <hr />
          <ServiceSelection companyId={companyId} />
          <hr />
          <div className={styles.elementContainer}>
            <h2 className={styles.subtitle}>informations pratiques pour le poste</h2>
            <WithLightTitle
              title="détails sur le poste"
              rightTitleComponent={
                <span className={styles.charCountLabel}>
                  {jobInfo?.length ?? 0}/3999 caractères
                </span>
              }
            >
              <TextArea
                control={control}
                name="jobInfo"
                maxLength={3999}
                className={styles.textArea}
                onBlurCapture={submit}
              />
            </WithLightTitle>
          </div>
          <hr />
          <div className={styles.elementContainer}>
            <h2>document(s) rattaché(s) à l'étude</h2>
            {companyRightQueryFetchStatus === FETCH_STATUS.FULFILLED && (
              <>
                {companyRightQuery.data?.hasClientGivenRights ? (
                  <div className={styles.clientPhotoRightsText}>
                    {`Le client vous a autorisé à prendre des photos jusqu'au ${moment(
                      companyRightQuery.data?.photoRights?.createdAt
                    )
                      .add(1, 'day')
                      .format('LLL')}`}
                  </div>
                ) : (
                  companyId !== undefined && (
                    <CompanyRightsModal companyId={companyId} positionStudyId={positionStudyId} />
                  )
                )}
              </>
            )}
            <WithLightTitle
              title="ajouter tous documents utiles"
              rightTitleComponent={
                <span className={styles.charCountLabel}>
                  {pluralFormat(files.length, 'fichier')}
                </span>
              }
            >
              {(isLoading || consultantRightQuery.isLoading) && (
                <div>
                  <ContentLoader height="4.5rem" width="100%" uniqueKey="documents">
                    <rect x="2%" y="10" rx="4" ry="4" width="50%" height="10" />
                    <rect x="2%" y="30" rx="4" ry="4" width="80%" height="10" />
                    <rect x="2%" y="50" rx="4" ry="4" width="80%" height="10" />
                  </ContentLoader>
                </div>
              )}
              {isError && <div>erreur</div>}
              {isSuccess && consultantRightQuery.isSuccess && (
                <FileDropzone
                  {...dropzoneData}
                  items={files}
                  onUpload={onFileDropped}
                  onDelete={item => {
                    if (item.id) onFileDeleted(item);
                  }}
                  onClick={item => {
                    if (item.id) {
                      fetchPositionStudyFileData.mutate(item.id ?? '', {
                        onSuccess: buffer => onFileOpen(buffer, item.file.type),
                      });
                    } else {
                      window.open(URL.createObjectURL(item.file));
                    }
                  }}
                />
              )}
            </WithLightTitle>
          </div>
        </div>
      )}
      <ConsultantRightsModal
        onValidate={() => onFileDropAction(files)}
        open={openConsultantRightsModal}
        onClose={() => setOpenConsultantRightsModal(false)}
        onRefuse={() => setFiles(files.filter(file => file.status !== UploadStatus.InProgress))}
      />
      {positionBriefFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage
          error={{
            type: '',
            message: "Une erreur est survenue dans la récupération de l'étude de poste",
          }}
        />
      )}
    </PracticalContext.Provider>
  );
};

export default Practical;

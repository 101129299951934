import { Bullet } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import React from 'react';

import { CompletionStatusEnum } from 'src/Services/API';

export function getCompletionStatusData(status?: CompletionStatusEnum): {
  icon: React.ReactNode;
  label: string;
} {
  switch (status) {
    case CompletionStatusEnum.MISSING_INFO:
      return { icon: <Bullet variant="fill" color="danger" />, label: 'informations manquantes' };
    case CompletionStatusEnum.PENDING_UPDATE:
      return { icon: <Bullet variant="fill" color="blue" />, label: 'mise à jour attendue' };
    case CompletionStatusEnum.UP_TO_DATE:
      return { icon: <Bullet variant="fill" color="success" />, label: 'à jour' };
    default:
      return { icon: null, label: 'inconnu' };
  }
}

export interface Props {
  title: string;
  completionStatus?: CompletionStatusEnum;
  createdAt?: Date;
  updatedAt?: Date;
  service?: string;
  onClick: () => void;
  rightActions: React.ReactNode;
}

import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';

import { DetailedPositionStudy, UpdatePositionStudyBody } from 'src/Services/API';

import Practical from '../RH/Practical';
import Profile from '../RH/Profile';
import Tests from '../RH/Tests';
import Handicaps from '../Security/Handicaps';
import Instructions from '../Security/Instructions';
import ProtectionEquipment from '../Security/ProtectionEquipment';
import Risks from '../Security/Risks';
import ComputerScience from '../Skills/ComputerScience.component';
import Environments from '../Skills/Environments.component';
import MachinesAndEquipment from '../Skills/MachinesAndEquipment.component';
import MaterialsAndProducts from '../Skills/MaterialsAndProducts.components';
import SkillsAndTasks from '../Skills/SkillsAndTasks.component';
import TechnicsAndProcesses from '../Skills/TechnicsAndProcesses.component';
import Caces from '../Training/Caces';
import Diplomas from '../Training/Diplomas';
import DrivingLicenses from '../Training/DrivingLicenses';
import Habilitations from '../Training/Habilitations';
import { Languages } from '../Training/Languages';
import Learning from '../Training/Learning';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props {
  fetchPositionStudy: UseQueryResult<DetailedPositionStudy, unknown>;
  totalSkills: number;
  setTotalSkills: React.Dispatch<React.SetStateAction<number>>;
  updateMutation: UseMutationResult<
    DetailedPositionStudy,
    unknown,
    {
      initialPositionStudy: DetailedPositionStudy;
      modification: Partial<UpdatePositionStudyBody>;
    },
    unknown
  >;
  positionStudyId: string;
}

export enum SectionsRootPath {
  'hr' = 'hr/profile',
  'training' = 'training/diplomas',
  'security' = 'security/risks',
  'job' = 'job/skills',
}

interface PStudyNavigationNode {
  name: string;
  default: string;
  path: string;
  routes: {
    name: string;
    linkTo: string;
    component?: () => JSX.Element;
    path: string;
  }[];
}

type PStudyNav = { [key: string]: PStudyNavigationNode };

const baseRoute = '/studies/study/:positionStudyId';

export const navigation: PStudyNav = {
  hr: {
    name: 'ressources humaines',
    default: './hr/profile',
    path: `${baseRoute}/hr`,
    routes: [
      { name: 'profil', linkTo: './profile', path: `${baseRoute}/hr/profile`, component: Profile },
      {
        name: 'infos pratiques',
        linkTo: './usefulinfo',
        path: `${baseRoute}/hr/usefulinfo`,
        component: Practical,
      },
      {
        name: 'tests',
        linkTo: './tests',
        path: `${baseRoute}/hr/tests`,
        component: Tests,
      },
    ],
  },
  job: {
    name: 'métier',
    default: './job/skills',
    path: `${baseRoute}/job`,
    routes: [
      {
        name: 'compétences et tâches à effectuer',
        linkTo: './skills',
        path: `${baseRoute}/job/skills`,
        component: SkillsAndTasks,
      },
      {
        name: 'environnements et typologies',
        linkTo: './environments',
        path: `${baseRoute}/job/environments`,
        component: Environments,
      },
      {
        name: 'machines et matériel',
        linkTo: './machines',
        path: `${baseRoute}/job/machines`,
        component: MachinesAndEquipment,
      },
      {
        name: 'techniques et procédés',
        linkTo: './processes',
        path: `${baseRoute}/job/processes`,
        component: TechnicsAndProcesses,
      },
      {
        name: 'matériaux et produits',
        linkTo: './materials',
        path: `${baseRoute}/job/materials`,
        component: MaterialsAndProducts,
      },
      {
        name: 'informatique',
        linkTo: './cs',
        path: `${baseRoute}/job/cs`,
        component: ComputerScience,
      },
    ],
  },
  training: {
    name: 'formations',
    default: './training/diplomas',
    path: `${baseRoute}/training`,
    routes: [
      {
        name: 'formations et diplômes',
        linkTo: './diplomas',
        path: `${baseRoute}/training/diplomas`,
        component: Diplomas,
      },
      {
        name: 'permis',
        linkTo: './license',
        path: `${baseRoute}/training/license`,
        component: DrivingLicenses,
      },
      { name: 'CACES', linkTo: './caces', path: `${baseRoute}/training/caces`, component: Caces },
      {
        name: 'habilitations',
        linkTo: './clearances',
        path: `${baseRoute}/training/clearances`,
        component: Habilitations,
      },
      {
        name: 'langues',
        linkTo: './languages',
        path: `${baseRoute}/training/languages`,
        component: Languages,
      },
      {
        name: "cadence et temps d'apprentissage",
        linkTo: './learning',
        path: `${baseRoute}/training/learning`,
        component: Learning,
      },
    ],
  },
  security: {
    name: 'sécurité',
    default: './security/risks',
    path: `${baseRoute}/security`,
    routes: [
      { name: 'risque', linkTo: './risks', path: `${baseRoute}/security/risks`, component: Risks },
      {
        name: 'réglementaire et handicap',
        linkTo: './handicap',
        path: `${baseRoute}/security/handicap`,
        component: Handicaps,
      },
      {
        name: 'équipement de protection',
        linkTo: './protectionEquipment',
        path: `${baseRoute}/security/protectionEquipment`,
        component: ProtectionEquipment,
      },
      {
        name: 'consignes et accueil au poste',
        linkTo: './instructions',
        path: `${baseRoute}/security/instructions`,
        component: Instructions,
      },
    ],
  },
};

export function getCorrectAscendingTab(currentTab: SectionsRootPath) {
  switch (currentTab) {
    case SectionsRootPath['hr']:
      return SectionsRootPath.job;
    case SectionsRootPath['job']:
      return SectionsRootPath.training;
    case SectionsRootPath['training']:
      return SectionsRootPath.security;
    default:
      return SectionsRootPath.hr;
  }
}

export function getCorrectDescendingTab(currentTab: SectionsRootPath) {
  switch (currentTab) {
    case SectionsRootPath['security']:
      return SectionsRootPath.training;
    case SectionsRootPath['training']:
      return SectionsRootPath.job;
    case SectionsRootPath['job']:
      return SectionsRootPath.hr;
    default:
      return SectionsRootPath.hr;
  }
}

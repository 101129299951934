export const findZoneIdByAgencyId = (
  agencyId: string,
  establishmentRegions: Array<{
    zones: Array<{
      zoneId: string;
      agencies: Array<{ agencyId: string }>;
    }>;
  }>
): string | undefined => {
  for (const region of establishmentRegions) {
    for (const zone of region.zones) {
      for (const agency of zone.agencies) {
        if (agency.agencyId === agencyId) {
          return zone.zoneId;
        }
      }
    }
  }
  return undefined;
};

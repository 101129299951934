import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { PositionstudiesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchPositionStudiesWithoutDetail = (region: {
  regionId: string;
  regionTitle: string;
  agencyIds: string[];
}) => {
  const currentBrand = useSelector(getCurrentBrand);

  return useInfiniteQuery(
    [QueryKeys.fetchPositionStudiesWithoutDetail, region, currentBrand?.brandCodeApiHeader],
    async ({ pageParam = 0 }) => {
      if (!currentBrand?.brandCodeApiHeader) {
        return Promise.reject('No valid current brand');
      }

      return PositionstudiesService.positionStudiesControllerGetPositionStudiesWithoutDetail({
        body: {
          agencyIds: region.agencyIds,
          page: pageParam,
          brandCode: currentBrand.brandCodeApiHeader,
        },
      });
    },
    {
      getNextPageParam: page => page.nextPage,
    }
  );
};

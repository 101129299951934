import { STATISTICS_TYPES } from 'src/Components/Statistics/Statistics.types';
import { CompletionStatusEnum } from 'src/Services/API';

import { EnumViewType } from '../Home.types';

import { UseFetchPositionStudiesResult } from './LastPositionStudies.types';

export const getStatusMessage = (
  count: number,
  total: number,
  messageSingle: string,
  messagePlural: string
) => {
  if (count === 0) return `aucune étude de poste ${messageSingle} sur cette unité pour le moment`;
  const verb = count === 1 ? 'est' : 'sont';
  return `${count} edp soit ${((count / total) * 100)
    .toFixed(2)
    .replace('.', ',')}% du total ${verb} ${messagePlural}`;
};

export const getTitle = (
  fetchPositionStudiesResult: UseFetchPositionStudiesResult,
  totalPositionStudiesCount: number,
  displayedPositionStudiesCount: number,
  view: EnumViewType,
  unit: string,
  statusFilter: STATISTICS_TYPES,
  upToDateCount: number,
  pendingUpdateCount: number,
  missingInfoCount: number,
  riskyUpToDateCount: number,
  riskyPendingUpdateCount: number,
  riskyMissingInfoCount: number
) => {
  if (!fetchPositionStudiesResult.isSuccess || fetchPositionStudiesResult.isFetchingNextPage) {
    return '';
  }
  if (totalPositionStudiesCount === 0) {
    return 'aucune étude de poste réalisée sur cette unité pour le moment';
  }
  if (view === EnumViewType.LATEST) {
    return `les ${displayedPositionStudiesCount} dernières études de poste réalisées sur l'unité ${unit} :`;
  }
  switch (statusFilter) {
    case STATISTICS_TYPES.ALL:
      return `toutes les études de poste réalisées sur l'unité ${unit} :`;
    case STATISTICS_TYPES.UP_TO_DATE:
      return getStatusMessage(upToDateCount, totalPositionStudiesCount, 'à jour', 'à jour');
    case STATISTICS_TYPES.PENDING_UPDATE:
      return getStatusMessage(
        pendingUpdateCount,
        totalPositionStudiesCount,
        'en attente de mise à jour',
        'à mettre à jour'
      );
    case STATISTICS_TYPES.MISSING_INFO:
      return getStatusMessage(
        missingInfoCount,
        totalPositionStudiesCount,
        'avec des informations manquantes',
        'avec des informations manquantes'
      );
    case STATISTICS_TYPES.RISKY_POSITION:
      return getStatusMessage(
        riskyUpToDateCount + riskyPendingUpdateCount + riskyMissingInfoCount,
        totalPositionStudiesCount,
        'avec un poste à risque',
        'avec un poste à risque'
      );
    default:
      return '';
  }
};

export const mapStatisticsTypeToCompletionStatus = (
  statisticsType: STATISTICS_TYPES
): CompletionStatusEnum | null => {
  switch (statisticsType) {
    case STATISTICS_TYPES.UP_TO_DATE:
      return CompletionStatusEnum.UP_TO_DATE;
    case STATISTICS_TYPES.PENDING_UPDATE:
      return CompletionStatusEnum.PENDING_UPDATE;
    case STATISTICS_TYPES.MISSING_INFO:
      return CompletionStatusEnum.MISSING_INFO;
    default:
      return null;
  }
};

import React from 'react';

import { AuthProvider } from './Auth';
import { DashboardStateProvider } from './DashboardState';
import { ReactQueryProvider } from './ReactQuery';
import { ReduxProvider } from './Redux';

const Providers = ({ children }: { children: React.ReactElement }) => (
  <AuthProvider>
    <ReduxProvider>
      <ReactQueryProvider>
        <DashboardStateProvider>{children}</DashboardStateProvider>
      </ReactQueryProvider>
    </ReduxProvider>
  </AuthProvider>
);

export default Providers;

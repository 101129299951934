import { Eye } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { VidePasteque } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  DataTable,
  Loader,
  Tab,
  Tabs,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router-dom';

import { getCompletionStatusData } from 'src/Components/PositionStudyCard/PositionStudyCard.types';
import { useFetchPositionStudiesWithoutDetail } from 'src/Hooks/PositionStudies/useFetchPositionStudiesWithoutDetail';
import { PositionStudyWithoutDetail } from 'src/Services/API';

import styles from './PositionStudiesTable.module.scss';
import { PositionStudiesTableProps } from './PositionStudiesTable.types';
import { findZoneIdByAgencyId } from './PositionStudiesTable.utils';

export const PositionStudiesTable = ({
  regions,
  establishmentRegions,
}: PositionStudiesTableProps) => {
  const [selectedRegionId, setSelectedRegionId] = useState<string>(regions[0].regionId);

  const selectedRegion = useMemo(
    () => regions.find(region => region.regionId === selectedRegionId) ?? regions[0],
    [regions, selectedRegionId]
  );

  useEffect(() => {
    if (!regions.some(region => region.regionId === selectedRegionId))
      setSelectedRegionId(regions[0].regionId);
  }, [regions, selectedRegionId]);

  const { data, isFetchingNextPage, isLoading, fetchNextPage, hasNextPage } =
    useFetchPositionStudiesWithoutDetail(selectedRegion);

  const history = useHistory();
  const handleRowClick = useCallback(
    (row: { original: PositionStudyWithoutDetail }) => {
      history.push(`/studies/study/${row.original.positionStudyId}/hr/profile`);
    },
    [history]
  );

  const positionStudies = useMemo(() => data?.pages.flatMap(page => page.positionStudies), [data]);

  const columns = useMemo<ColumnDef<PositionStudyWithoutDetail>[]>(
    () => [
      {
        id: 'zone',
        header: 'dr',
        accessorFn: positionStudy =>
          findZoneIdByAgencyId(positionStudy.agencyId, establishmentRegions),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'agency',
        header: 'unité',
        accessorFn: positionStudy => positionStudy.agencyId,
        filterFn: 'arrIncludes',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'companyName',
        header: 'nom client',
        accessorFn: positionStudy => positionStudy.companyName,
        filterFn: 'arrIncludes',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'creationDate',
        header: 'date de création',
        accessorFn: positionStudy => moment(positionStudy.createdAt).format('L'),
        filterFn: 'arrIncludes',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'qualificationLabel',
        header: 'qualification',
        accessorFn: positionStudy => positionStudy.qualificationLabel,
        filterFn: 'arrIncludes',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'completionStatus',
        header: 'statut de complétion',
        accessorFn: positionStudy =>
          getCompletionStatusData(positionStudy.completionStatus).label ?? 'inconnu',
        filterFn: 'arrIncludes',
        getUniqueValues: () => ['à jour', 'informations manquantes', 'mise à jour attendue'],
        enableSorting: false,
        cell: ({ row }) => {
          const statusData = getCompletionStatusData(row.original.completionStatus);
          return (
            <div className={styles.completionStatus}>
              <div className={styles.completionStatusIcon}>{statusData.icon}</div>
              <p>{statusData.label}</p>
            </div>
          );
        },
      },
      {
        id: 'lastUpdateDate',
        header: 'dernière mise à jour',
        accessorFn: positionStudy => moment(positionStudy.updatedAt).format('L'),
        filterFn: 'arrIncludes',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'actions',
        header: 'actions',
        cell: ({ row }) => (
          <Button
            data-no-padding-left
            data-full-height
            size="medium"
            variant="tertiary"
            onClick={e => {
              e.stopPropagation();
              handleRowClick(row);
            }}
          >
            <Eye variant="line" />
          </Button>
        ),
      },
    ],
    [establishmentRegions, handleRowClick]
  );

  const { ref: nextPageRef } = useInView({
    onChange: inView => inView && fetchNextPage(),
  });

  return (
    <div className={styles.tableContainer}>
      <Tabs color="navy" size="medium" value={selectedRegionId} hideSeparator>
        {regions
          .sort((a, b) => a.regionId.localeCompare(b.regionId, undefined, { numeric: true }))
          .map(region => (
            <Tab
              key={region.regionId}
              value={region.regionId}
              onClick={() => setSelectedRegionId(region.regionId)}
            >
              {`Dir. Op - ${region.regionId}`}
            </Tab>
          ))}
      </Tabs>
      {!isLoading && (
        <DataTable
          size="small"
          headerColor="white"
          columns={columns}
          data={positionStudies ?? []}
          rowStyle="classic"
          containerClassName={styles.table}
          onRowClick={handleRowClick}
          empty={
            isFetchingNextPage || hasNextPage ? (
              <div></div>
            ) : (
              <div className={styles.emptyIllustration}>
                <VidePasteque />
                aucune edp modifiée il y a moins de 2 ans ne correspond à votre recherche
              </div>
            )
          }
        />
      )}
      {isLoading || isFetchingNextPage ? (
        <div className={styles.illustration}>
          <Loader size="small" />
          {`chargement des études de poste${isFetchingNextPage ? ' suivantes' : ''}`}
        </div>
      ) : hasNextPage ? (
        <div ref={nextPageRef} />
      ) : null}
    </div>
  );
};
